@media only screen and (max-width: 600px) {
  .site-logo img {
    height: 160px;
    width: 320px;
  }
  .site-header{
    margin: 0px;
  }
}
@media only screen and (max-width: 430px) {
  .site-logo img {
    height: 120px;
    width: 280px;
  }
}
@media only screen and (max-width: 380px) {
  .site-logo img {
    height: 100px;
    width: 220px;
  }
}
