:root {
  --primary-color: #3b864c;
  --secondaray-color: #f1c424;
  --grey-color: #303130;
  --background-color: #ebf3ed;
  --white-color: #fff;
  --black-color: #000;
  --dark-color: #4f4f4f;

  --body-font-family: 'Sora';

  --header-heading-font-size: 24px;
  --base-font-size: 20px;
  --body-font-size: 14px;
  --heading-font-size: 36px;
  --sub-heading-font-size: 16px;
  --text-font-size: 24px;
  --image-box-font-size: 12px;
  --icon-font-size: 10px;

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;
  --font-weight-large: 700;
  --font-weight-bolder: 800;
}
* {
  box-sizing: border-box;
}
.container {
  max-width: 1140px;
  margin: auto;
  padding: 0;
  width: 100%;
}
body {
  font-family: 'Sora';
}

.MuiRating-icon:hover {
  border-radius: 50%;
  background-color: rgba(180, 180, 107, 0.582);
  /*  height: 25px;
  width: 25px; */
}
/*Header Css Style Start*/
.announcement-bar {
  background-color: var(--black-color);
  text-align: center;
  padding: 14px 0;
}
.announcement-bar p {
  font-size: var(--body-font-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--body-font-family);
  color: var(--white-color);
  margin: 0;
}
header .site-header {
  text-align: center;
  padding-top: 50px;
  margin-bottom: 50px;
}
header .site-header h3 {
  font-size: var(--header-heading-font-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--body-font-family);
  color: var(--primary-color);
  margin: 0;
  padding-top: 14px;
}
/*Header Css Style End*/
/*Book Shell Css Style Start*/
.book-shelf-section {
  margin-bottom: 20px;
}
.book-shelf-section .card-style {
  border-radius: 12px;
  padding: 18px 18px 18px;
  background: linear-gradient(
    1.12deg,
    rgba(59, 134, 76, 0.1) -7.86%,
    rgba(255, 255, 255, 0.1) 99.05%
  );
  padding-top: 50px;
}
.book-shelf-section p {
  font-size: var(--base-font-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--body-font-family);
  color: var(--dark-color);
  margin: 0;
  padding-bottom: 8px;
}
.book-shelf-section .img-box {
  display: flex;
  align-items: center;
}
.book-shelf-section h2 {
  font-size: var(--heading-font-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
  padding-right: 10px;
}
.book-shelf-section .image-box {
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: center;
  gap: 20px;
}
.book-shelf-section .image-box .inner-img {
  border: 2px dashed #ebedf3;
  border-radius: 8px;
  padding: 14px 20px;
}
.book-shelf-section .image-box .inner-img h5 {
  font-size: var(--base-font-size);
  font-weight: var(--font-weight-bolder);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
  padding-bottom: 8px;
}
.book-shelf-section .image-box .inner-img p {
  font-size: var(--sub-heading-font-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--body-font-family);
  color: #baa9b6;
  margin: 0;
  padding: 0;
}
/*Book Shell Css Style End*/
/*Book Card Css Style Start*/
.books-card-section .col-lg-11 {
  padding: 0;
}
.books-card-section .book-box .row {
  align-items: center;
}
.books-card-section .book-box {
  border-radius: 12px;
  background-color: var(--background-color);
  margin-bottom: 20px;
  padding: 30px;
  position: relative;
}
.books-card-section .book-box .owl-nav button {
  border: 1px solid #e4e4e4 !important;
  border-radius: 4px !important;
  height: 38px !important;
  width: 40px !important;
  background: #e4e4e4 !important;
}
.books-card-section .book-box .owl-nav button span {
  font-size: 24px;
  line-height: 0.7;
}
.books-card-section .book-box .owl-nav {
  position: absolute;
  top: -80px;
  right: 0;
}
.books-card-section .book-box .owl-theme .owl-nav [class*='owl-']:hover {
  color: #000 !important;
}
/*.books-card-section .book-box .book-card-style {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
}*/
.books-card-section .book-score-box {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 40px;
}
.books-card-section .book-score-box .icon-box {
  display: flex;
  align-items: center;
  gap: 14px;
}
.books-card-section .book-score-box .icon-box i {
  color: var(--secondaray-color) !important;
  font-size: 20px !important;
  /*    border: 1px solid #EBF3ED;*/
  border-radius: 50%;
  padding: 5px;
  background: #ebf3ed;
}
.books-card-section .book-score-box .icon-box i:hover {
  color: var(--secondaray-color);
  font-size: 20px;
  border: 1px solid #eceac5;
  border-radius: 50%;
  padding: 5px;
  background: #eceac5;
}
.books-card-section .book-score-box p {
  font-size: var(--body-font-size);
  font-weight: var(--font-weight-light);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
}
.books-card-section .book-score-box h5 {
  font-size: var(--sub-heading-font-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
  padding-left: 10px;
}
.books-card-section .book-box .subscription-box p {
  font-size: var(--base-font-size);
  font-weight: var(--font-weight-normal);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
  padding-top: 16px;
  padding-bottom: 4px;
}
.books-card-section .book-box .subscription-box h3 {
  font-size: var(--text-font-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
  padding-bottom: 20px;
}
.books-card-section .book-box .subscription-box button {
  font-size: var(--body-font-size);
  font-weight: var(--font-weight-normal);
  font-family: var(--body-font-family);
  color: var(--white-color);
  border: 1px solid var(--black-color);
  background: var(--black-color);
  border-radius: 4px;
  padding: 6px 18px;
}
.books-card-section .book-box .subscription-box button.reward-button {
  font-size: var(--body-font-size);
  font-weight: var(--font-weight-normal);
  font-family: var(--body-font-family);
  color: var(--white-color);
  border: 1px solid var(--secondaray-color);
  background: var(--secondaray-color);
  border-radius: 4px;
  padding: 6px 16px;
  margin-top: 15px;
}
.books-card-section .book-box .content-box h4 {
  font-size: var(--body-font-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
  padding: 10px 0;
}
.books-card-section .book-box .content-box p {
  font-size: var(--image-box-font-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
  padding-bottom: 20px;
  line-height: 1.3;
}
.books-card-section .book-box .content-box p span {
  font-size: var(--body-font-size);
  font-weight: var(--font-weight-large);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
}
.books-card-section .book-box .content-box h5 {
  font-size: var(--icon-font-size);
  font-weight: var(--font-weight-light);
  font-family: var(--body-font-family);
  color: var(--black-color);
  margin: 0;
  padding-bottom: 10px;
}
.books-card-section .book-box .icon-box {
  display: flex;
  align-items: center;
  gap: 0px;
}
.books-card-section .book-box .icon-box i {
  color: var(--secondaray-color) !important;
  font-size: 15px !important;
  /*    border: 1px solid #EBF3ED;*/
  border-radius: 50%;
  padding: 2px;
  background: none;
}
.books-card-section .book-box .icon-box i:hover {
  color: var(--secondaray-color);
  font-size: 15px;
  border: 1px solid #eceac5;
  border-radius: 50%;
  padding: 2px;
  background: #eceac5;
}
.books-card-section .book-box select {
  border: 1px solid #bcc3be;
  border-radius: 6px;
  width: 100%;
  font-size: var(--image-box-font-size);
  font-weight: var(--font-weight-normal);
  font-family: var(--body-font-family);
  color: #01010166;
  padding: 4px;
  outline: none;
}
.books-card-section .book-box .book-card-style .inner-content {
  min-height: 170px;
}

.books-card-section .sec-book-box {
  background-color: #f8f8f8 !important;
}
.books-card-section .side-bar {
  position: relative;
  height: 100%;
  text-align: center;
}
.books-card-section .side-bar button {
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  background: var(--primary-color);
  font-size: var(--image-box-font-size);
  font-weight: var(--font-weight-normal);
  font-family: var(--body-font-family);
  color: var(--white-color);
  padding: 6px 18px;
  position: relative;
  z-index: 9;
  box-shadow: 0px 4px 20px 0px #3b864c66;
}
.books-card-section .side-bar:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: #3b864c;
  z-index: 0;
}
.books-card-section .row:last-child .side-bar:before {
  background: linear-gradient(#3b864c, transparent);
}
/*Book Card Css Style End*/
/*Footer Style Start*/
footer.site-footer {
  background: var(--black-color);
  padding: 34px 0;
  text-align: center;
}
.copy-right-section {
  background: var(--primary-color);
  padding: 22px 0;
  text-align: center;
}
.copy-right-section p {
  font-size: var(--image-box-font-size);
  font-weight: var(--font-weight-normal);
  font-family: var(--body-font-family);
  color: var(--white-color);
  margin: 0;
}
/*Footer Style End*/
/*Load More Section Style Start*/
.load-more-section {
  padding: 60px 0;
}
.load-more-section .load-more-button {
  text-align: center;
}
.load-more-section button {
  font-size: var(--sub-heading-font-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--body-font-family);
  color: #999999;
  border: none;
  background: none;
}
/*Load More Section Style End*/
.books-card-section .surprise-box .date-style {
  display: none;
}
.books-card-section .first-book-score {
  display: unset;
}
.books-card-section .sec-book-score {
  display: none;
}
.books-card-section .book-box .card-box .reading-bar {
  display: none;
}
/*Scroll Bar Css Style Start*/
.scrolltop .icon {
  text-align: center;
  margin-top: 2px;
}
.scrolltop {
  position: fixed;
  bottom: 100px;
  right: 60px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: none;
  z-index: 1000;
}
.scrolltop svg path {
  fill: var(--white-color);
  padding: 0px;
}
/*Scroll Bar Css Style End*/
/*Media Query Start*/
@media screen and (max-width: 1024px) {
  header {
    margin: 0 20px;
  }
  main {
    margin: 0 20px;
  }
  .books-card-section .book-box .subscription-box button {
    font-size: var(--image-box-font-size);
  }
  .books-card-section .side-bar button {
    padding: 6px 11px;
  }
}
@media screen and (max-width: 575px) {
  header .site-header h3 {
    font-size: var(--body-font-size);
    padding-top: 14px;
  }
  header .site-header {
    padding: 26px 0 20px;
    margin: 0 20px;
  }
  .announcement-bar {
    display: none;
  }
  .book-shelf-section .img-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .book-shelf-section .card-style {
    padding-top: 26px;
  }
  .book-shelf-section p {
    font-size: var(--body-font-size);
    padding-bottom: 0;
  }
  .book-shelf-section h2 {
    font-size: var(--base-font-size);
    padding-right: 6px;
  }
  .books-card-section .subscription-box .img-box {
    display: none;
  }
  .book-shelf-section .image-box {
    display: none;
  }
  .book-shelf-section .card-style {
    text-align: center;
  }
  .books-card-section .book-box .subscription-box p {
    font-size: var(--sub-heading-font-size);
  }
  .books-card-section .book-box .subscription-box h3 {
    padding-bottom: 0;
    font-size: var(--base-font-size);
  }
  .books-card-section .surprise-box {
    display: flex;
    align-items: center;
    padding: 0 0 10px;
  }
  .books-card-section .surprise-box .date-style span {
    font-size: var(--body-font-size);
    font-weight: var(--font-weight-normal);
    font-family: var(--body-font-family);
    color: var(--primary-color);
  }
  .books-card-section .surprise-box .date-style {
    display: unset;
    position: relative;
    margin-left: 16px;
  }
  .books-card-section .surprise-box .date-style::before {
    content: '';
    border: 1px solid;
    height: 16px;
    position: absolute;
    top: 4px;
    left: -8px;
  }
  .books-card-section .side-bar {
    display: none;
  }
  .books-card-section .book-box .subscription-box button {
    font-size: var(--image-box-font-size);
    padding: 4px 10px;
  }
  .books-card-section .first-book-score {
    display: none;
  }
  .books-card-section .sec-book-score {
    display: unset;
  }
  /* .books-card-section .book-box .subscription-box {
        margin-bottom: 20px;
    }*/
  .books-card-section .book-box.active {
    padding: 20px 20px 100px 20px;
  }
  .books-card-section .book-box {
    padding: 16px 20px 18px 16px;
    min-height: unset;
    position: relative;
  }
  .books-card-section .book-score-box {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .books-card-section .book-box .card-box {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
  .books-card-section .book-box .card-box .image-box {
    width: 50%;
  }
  .books-card-section .book-box .card-box .content-box {
    width: 50%;
  }
  .books-card-section .book-box .content-box h4 {
    font-size: 18px;
    padding: 0;
  }
  .books-card-section .book-box .content-box p {
    font-size: var(--body-font-size);
    padding-bottom: 14px;
    padding-top: 10px;
  }
  .books-card-section .book-box .content-box h5 {
    font-size: var(--icon-font-size);
    padding-bottom: 6px;
  }
  .books-card-section .book-box .book-card-style .inner-content .icon-box-style {
    background: #fff;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    text-align: center;
  }
  .books-card-section .book-box .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .books-card-section .book-box .icon-box i {
    font-size: 18px;
  }
  .books-card-section .book-box .card-box .reading-bar span {
    font-size: var(--image-box-font-size);
    font-weight: var(--font-weight-normal);
    font-family: var(--body-font-family);
    color: var(--black-color);
  }
  .books-card-section .book-box .card-box .reading-bar {
    display: block;
    padding-bottom: 10px;
  }
  .books-card-section .book-box .book-card-style .inner-content {
    min-height: unset;
  }
  .books-card-section .book-box .owl-nav {
    position: absolute;
    bottom: -75px;
    left: 38%;
    top: unset;
    right: unset;
  }
  .books-card-section .book-box .owl-nav button {
    border-radius: 50% !important;
  }
  .tab-content {
    display: none;
  }
  .book-box.active .tab-content {
    display: block;
  }
  .subscription-box:after {
    content: '\';
    font-family: 'FontAwesome';
    display: block;
    width: 30px;
    height: 30px;
    background: var(--primary-color);
    color: var(--white-color);
    position: absolute;
    right: 24px;
    top: 21px;
    padding: 3px 5px 0 6px;
    border-radius: 50%;
    line-height: 2;
    text-align: center;
  }
  .book-box.active .subscription-box:after {
    content: '\';
    line-height: 2;
    text-align: center;
  }
  .books-card-section .book-box .subscription-box button.reward-button {
    padding: 2px 14px;
    margin-top: 0;
    margin-left: 10px;
  }
  .load-more-section {
    padding: 30px 0;
  }
}
/*Media Query End*/
